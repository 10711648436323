import {useEffect, useState} from 'react'
import {KTIcon, useDebounce} from '../../../../_metronic/helpers'
import {UsersListFilter} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListFilter'
import AddNewCustomer from './AddNewCustomer'
import {User} from '../../../../app/modules/apps/user-management/users-list/core/_models'
import * as XLSX from 'xlsx'

const TableToolbar = ({data}) => {
  return (
    <div>
      <ToolbarActions data={data} />
    </div>
  )
}

const ToolbarActions = ({data}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [isUserLoading, setUserLoading] = useState(true)

  const openAddUserModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const getUser = () => {
    setUserLoading(true)
    setTimeout(() => {
      setUserLoading(false)
    }, 1000)
  }
  const [selectedRole, setSelectedRole] = useState<string>('')
  useEffect(() => {
    getUser()
  }, [setSelectedRole])

  const exportAsExcel = () => {
    if (data.length > 0) {
      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'SalesData')
      XLSX.writeFile(wb, 'customerReport.xlsx')
    }
  }

  return (
    <div className='d-flex'>
      <button type='button' className='btn btn-secondary mr-2' onClick={exportAsExcel}>
        <KTIcon iconName='send' className='fs-2' />
        Export to Excel
      </button>

      <span className='mx-2'></span>
      <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add Customer
      </button>
      {modalOpen && <AddNewCustomer close={closeModal} />}
    </div>
  )
}

export default TableToolbar
