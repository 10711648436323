import axios, {AxiosResponse} from 'axios'

import {UsersQueryResponse} from '../app/modules/apps/user-management/users-list/core/_models'

const BaseUrl = process.env.REACT_APP_BACKEND_URL

// Define a function that takes an API token as a parameter
const getSubscriptions = async (apiToken: string | undefined): Promise<UsersQueryResponse> => {
  try {
    const response: AxiosResponse<UsersQueryResponse> = await axios.post(
      `${BaseUrl}/subscription/get-subscriptions`,
      {},
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    )
    return response.data
  } catch (error) {
    throw error
  }
}

const getCouupons = async (apiToken: string | undefined): Promise<UsersQueryResponse> => {
  try {
    const response: AxiosResponse<UsersQueryResponse> = await axios.post(
      `${BaseUrl}/couponcodes/get-couponcodes`,
      {},
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    )
    console.log('getCouupons response', response.data)
    return response.data
  } catch (error) {
    throw error
  }
}

const getAdmins = async (apiToken: string | undefined): Promise<UsersQueryResponse> => {
  try {
    const response: AxiosResponse<UsersQueryResponse> = await axios.get(
      `${BaseUrl}/admin/get-all-admins`,
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    )
    console.log('getCouupons response', response.data)
    return response.data
  } catch (error) {
    throw error
  }
}

const getPermissionList = async (apiToken: string | undefined): Promise<UsersQueryResponse> => {
  try {
    const response: AxiosResponse<UsersQueryResponse> = await axios.get(
      `${BaseUrl}/permission/get-permisisons`,
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    )
    console.log('getCouupons response', response.data)
    return response.data
  } catch (error) {
    throw error
  }
}

const getDashboard = async () => {
  try {
    const response = await axios.get(`${BaseUrl}/dashboard/`)
    console.log(response)
    return response.data
  } catch (error) {
    throw error
  }
}

const getProfile = async (apiToken: string | undefined) => {
  try {
    const response = await axios.get(`${BaseUrl}/admin/profile`, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    })
    console.log(response)
    return response.data
  } catch (error) {
    throw error
  }
}
const getStripeSubscriptions = async (
  apiToken: string | undefined
): Promise<UsersQueryResponse> => {
  try {
    const response: AxiosResponse<UsersQueryResponse> = await axios.get(
      `${BaseUrl}/subscription/stripe-subscriptions`,
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    )
    console.log(response)
    return response.data
  } catch (error) {
    throw error
  }
}

const getsubscriptionStatictics = async (
  apiToken: string | undefined
): Promise<UsersQueryResponse> => {
  try {
    const response: AxiosResponse<UsersQueryResponse> = await axios.get(
      `${BaseUrl}/subscription/stripe-statics`,
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    )
    console.log('fafaf fa aw aw', response.data)
    return response.data
  } catch (error) {
    throw error
  }
}

export {
  getSubscriptions,
  getCouupons,
  getAdmins,
  getPermissionList,
  getDashboard,
  getProfile,
  getStripeSubscriptions,
  getsubscriptionStatictics,
}
