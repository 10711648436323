import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'

import Home from '../pages/dashboard/Home'

const PrivateRoutes = () => {
  //custom pages
  const CustomersPage = lazy(() => import('../pages/dashboard/Customers/Customers'))
  const SingleCustomerPage = lazy(() => import('../pages/dashboard/Customers/Customer'))
  const SubscriptionsPage = lazy(() => import('../pages/dashboard/Subscriptions/Subscriptions'))
  const AddSubscriptionsPage = lazy(
    () => import('../pages/dashboard/Subscriptions/AddSubscription')
  )
  const Clients = lazy(() => import('../pages/dashboard/clients/index'))
  const CouponsListPage = lazy(() => import('../pages/dashboard/Subscriptions/Coupons'))
  const UsersListPage = lazy(() => import('../pages/dashboard/UserManagement/Users/UserList'))
  const SingleUserPage = lazy(() => import('../pages/dashboard/UserManagement/Users/UserProfile'))
  const RolesListPage = lazy(() => import('../pages/dashboard/UserManagement/Roles/RolesList'))
  const PermissionsPage = lazy(() => import('../pages/dashboard/UserManagement/Permissions'))
  const ProfilePage = lazy(() => import('../pages/profile/Profile'))
  const EditProfilePage = lazy(() => import('../pages/profile/EditProfile'))
  const NotificationsPage = lazy(() => import('../pages/profile/Notifications'))
  const RefundsPage = lazy(() => import('../pages/dashboard/Refunds/Refunds'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<Home />} />
        <Route
          path='clients'
          element={
            <SuspensedView>
              <CustomersPage />
            </SuspensedView>
          }
        />
        <Route
          path='customers'
          element={
            <SuspensedView>
              <Clients />
            </SuspensedView>
          }
        />
        <Route
          path='customers/:id'
          element={
            <SuspensedView>
              <AddSubscriptionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='subscriptions/subscriptions-list'
          element={
            <SuspensedView>
              <SubscriptionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='subscriptions/:id'
          element={
            <SuspensedView>
              <SingleCustomerPage />
            </SuspensedView>
          }
        />

        <Route
          path='subscriptions/coupons'
          element={
            <SuspensedView>
              <CouponsListPage />
            </SuspensedView>
          }
        />
        <Route
          path='user-management/users'
          element={
            <SuspensedView>
              <UsersListPage />
            </SuspensedView>
          }
        />
        <Route
          path='user-management/users/:id'
          element={
            <SuspensedView>
              <SingleUserPage />
            </SuspensedView>
          }
        />
        <Route
          path='user-management/roles'
          element={
            <SuspensedView>
              <RolesListPage />
            </SuspensedView>
          }
        />
        <Route
          path='user-management/permissions'
          element={
            <SuspensedView>
              <PermissionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='profile'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='profile/edit/:id'
          element={
            <SuspensedView>
              <EditProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='/notifications'
          element={
            <SuspensedView>
              <NotificationsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/refunds'
          element={
            <SuspensedView>
              <RefundsPage />
            </SuspensedView>
          }
        />
        {/* <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} /> */}
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
