import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import axios from 'axios'
import {toast} from 'react-toastify'

export function ResetPassword() {
  const initialValues = {
    newPassword: '',
    confirmPassword: '',
  }

  const forgotPasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
  })
  const token = useParams()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const BaseUrl = process.env.REACT_APP_BACKEND_URL
  const [status, setStatus] = useState('')
  const [loadingVerify, setLoadingVerify] = useState(false)

  const validateURL = async () => {
    setLoadingVerify(true)
    await axios
      .get(`${BaseUrl}/auth/verify/${token.token}`)
      .then((response) => {
        if (response.data.message === 'token-verified') {
          setStatus('success')
        }
      })
      .catch((error) => {
        console.log(error)
        setHasErrors(true)
        setStatus('failed')
      })
      .finally(() => {
        setLoadingVerify(false)
      })
  }

  useEffect(() => {
    validateURL()
  }, [token.token])

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        await axios
          .patch(`${BaseUrl}/auth/restPassword/${token.token}`, {
            password: values.newPassword,
            newPassword: values.confirmPassword,
          })
          .then((response) => {
            console.log(response.data)
            toast.success('Password reset successful')
            window.location.href = '/auth/login'
            formik.resetForm()
          })
          .catch((error) => {
            console.error(error)
            toast.error(error.response.data.message)
          })
      } catch (error) {
        return error
      }
    },
  })

  return (
    <>
      {loadingVerify ? (
        <>
          <div className='d-flex flex-column align-items-center justify-content-center min-vh-100'>
            <div className='loading-container'>
              <div className='spinner-border text-primary' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {status === 'success' ? (
            <>
              <div className='d-flex flex-column align-items-center justify-content-center min-vh-100'>
                <div className='py-20'>
                  <form
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    onSubmit={formik.handleSubmit}
                  >
                    <div className='text-start mb-11'>
                      <h1 className='text-dark fw-bolder mb-3 fs-3x'>Setup New password</h1>
                      <div className='text-gray-500 fw-semibold fs-6'>
                        Enter the code that we've sent to your email.
                      </div>
                    </div>

                    <div className='fv-row mb-8'>
                      <label className='form-label fw-bolder text-gray-900 fs-6'>
                        New Password
                      </label>
                      <input
                        type='password'
                        placeholder='Enter new password'
                        autoComplete='off'
                        {...formik.getFieldProps('newPassword')}
                        className={clsx(
                          'form-control form-control-solid',
                          {'is-invalid': formik.touched.newPassword && formik.errors.newPassword},
                          {
                            'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                          }
                        )}
                        name='newPassword'
                      />
                      {formik.touched.newPassword && formik.errors.newPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.newPassword}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='fv-row mb-8'>
                      <label className='form-label fw-bolder text-gray-900 fs-6'>
                        Confirm password
                      </label>
                      <input
                        type='password'
                        placeholder='Confirm password'
                        autoComplete='off'
                        {...formik.getFieldProps('confirmPassword')}
                        className={clsx(
                          'form-control form-control-solid',
                          {
                            'is-invalid':
                              formik.touched.confirmPassword && formik.errors.confirmPassword,
                          },
                          {
                            'is-valid':
                              formik.touched.confirmPassword && !formik.errors.confirmPassword,
                          }
                        )}
                        name='confirmPassword'
                      />
                      {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.confirmPassword}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}

                    {/* begin::Form group */}
                    <div className='pb-lg-0 d-flex flex-start gap-4'>
                      <button
                        type='submit'
                        id='kt_password_reset_submit'
                        className='btn btn-primary'
                      >
                        <span className='indicator-label'>Continue</span>
                        {loading && (
                          <span className='indicator-progress'>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                      <Link to='/auth/login'>
                        <button
                          type='button'
                          id='kt_login_password_reset_form_cancel_button'
                          className='btn btn-light text-primary'
                          disabled={formik.isSubmitting || !formik.isValid}
                        >
                          Return to Log in
                        </button>
                      </Link>{' '}
                    </div>
                  </form>
                </div>
              </div>
            </>
          ) : (
            <div className='d-flex flex-column align-items-center justify-content-center min-vh-100'>
              <div className='alert alert-danger w-25'>
                <div className='d-flex flex-column align-items-center'>
                  <h4 className='alert-heading'>Something went wrong</h4>
                  <p className='font-weight-bold text-center'>
                    The specified Checkout Session could not be found. This error is usually caused
                    by using the wrong API key or visiting an expired Session. Please make sure the
                    Session is not expired and that the API keys used to initialize zimplebook and
                    create the Session from the same account.
                  </p>
                  <div>
                    <Link to='/auth/login'>Return to Log in</Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}
