import React from 'react'

import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import clsx from 'clsx'

const SearchBar = () => {
  return <div className='app-navbar flex-shrink-0 align-items-center ms-md-4 me-md-4'></div>
}

export default SearchBar
