/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {QueryState} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {ProfitChart} from '../../../components/dashboard/CustomCharts/ProfitChart'
import {PurchaseSummaryChart} from '../../../components/dashboard/CustomCharts/PurchaseSummaryChart'
import {StatsNew} from '../../../components/dashboard/CustomWidgets/StatsNew'
import {StatsAlt} from '../../../components/dashboard/CustomWidgets/StatsAlt'
import MixedWidget from '../../../components/dashboard/CustomWidgets/MixedWidget'
import SalesbyCountry from '../../../components/dashboard/CustomWidgets/SalesbyCountry'
import RefundStats from '../../../components/dashboard/CustomWidgets/RefundStats'
import RefundsTable from '../../../components/dashboard/CustomTable/RefundsTable'
import {getDashboard} from '../../../api/_requests'
import {toast} from 'react-toastify'
import {UsersListLoading} from '../../modules/apps/user-management/users-list/components/loading/UsersListLoading'
import {useAuth} from '../../modules/auth'
import {getUsers} from '../../../api/clients'
import {CustomTable} from '../../../components/dashboard/CustomTable/CustomersTable/CustomersTable'
import {RefundMode, RefundModel, refunCount} from '../../../models/refundModel'
import {getRefunds, refundCount} from '../../../api/_request.refunds'
import {RootState} from '../../../redux/reduxStore'
import {useSelector} from 'react-redux'
import axios from 'axios'

const state: QueryState = {
  page: 1,
  items_per_page: 10,
}

interface Data {
  balanceAmount: number
  pendingAmmount: number
  balanceCount: number
  customersCount: number
  numberOfClients: number
  subscriptionCount: number
  totalCashOnHand: number
  subcriptionsCouuntWithPackages: {subscriptionName: string; subscribedUserCount: number}[]
  profilePictures: string[]
  countryList: countryCounts[]
  revenueForMonths: {month: string; year: number; totalBalance: number}[]
  revenueForSevenDays: {date: string; dayName: string; totalBalance: number}[]
}

interface countryCounts {
  country: string
  count: number
}

const HomePage: FC = () => {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<Data>({
    balanceAmount: 0,
    pendingAmmount: 0,
    balanceCount: 0,
    customersCount: 0,
    numberOfClients: 0,
    subscriptionCount: 0,
    totalCashOnHand: 0,
    subcriptionsCouuntWithPackages: [],
    profilePictures: [],
    countryList: [],
    revenueForMonths: [],
    revenueForSevenDays: [],
  })

  const BaseUrl = process.env.REACT_APP_BACKEND_URL

  const dashboardData = async () => {
    try {
      setLoading(true)
      await toast.promise(
        getDashboard().then((result: any) => {
          const dashdata = result.results || []
          setData(dashdata)
        }),
        {
          pending: 'syncing data...',
          success: 'Data sync complete',
          error: 'Something went wrong',
        }
      )
    } catch (error: any) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const {currentUser} = useAuth()
  const customerOnChange = useSelector((state: RootState) => state.customer)

  // console.log(currentUser)

  const [refundsData, setRefundsData] = useState<RefundModel[]>([])
  const getRefundsData = async () => {
    try {
      await axios
        .get(`${BaseUrl}/refund`)
        .then((result) => {
          console.log('Refunds', result.data.refunds)
          setRefundsData(result.data.refunds)
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (error) {
      throw error
    }
  }

  const [data2, setData2] = useState<any[]>([])
  const [data3, setData3] = useState<RefundMode[]>([])
  const [data4, setData4] = useState<refunCount | null>(null)
  useEffect(() => {
    dashboardData()
    getRefundsData()
    setLoading(true)
    try {
      getUsers(currentUser?.api_token as string).then((result: any) => {
        const users = result.clients || []
        setData2(users)
      })

      getRefunds(currentUser?.api_token as string).then((result: any) => {
        setData3(result.refunds.data)
      })
      refundCount(currentUser?.api_token as string).then((result: any) => {
        setData4(result.payload)
        console.log(result)
      })
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [customerOnChange])

  useEffect(() => {}, [currentUser?.api_token])

  const countryArray = Array.isArray(data) ? data : [data]

  return (
    <>
      {isLoading === true ? (
        <></>
      ) : (
        <>
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-md-8 col-lg-8 col-xl-8 col-xxl-9 d-flex flex-column justify-content-between'>
              <div className='row'>
                <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 pe-4 mb-2 mb-md-4'>
                  <StatsNew
                    className='card-xl-strech h-100'
                    color=''
                    title='2.2'
                    subtitle='1000 to goal'
                    description='Total Sales'
                    total={data.balanceCount.toString()}
                    progress={data.balanceCount.toString()}
                  />
                </div>
                <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 ps-4 mb-2 mb-md-4'>
                  <StatsAlt
                    className='card-xl-strech h-100'
                    color=''
                    title=''
                    subtitle=''
                    description='New Customers This Month'
                    total={data.numberOfClients.toString()}
                    profilePictures={data.profilePictures}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                  <ProfitChart
                    className='mb-0 mb-xl-8'
                    series={[
                      {
                        name: 'Net Profit',
                        data: data.revenueForSevenDays.map((item) => item.totalBalance),
                      },
                      {
                        name: '',
                        data: [],
                      },
                    ]}
                    categories={data.revenueForSevenDays.map((item) => item.dayName)}
                    title='Sales This Month'
                    subtitle='Users from All Channels'
                    totalAmount={(data.balanceAmount + data.pendingAmmount).toFixed(2)}
                    target='Another $100,000 to Goal'
                  />
                </div>
              </div>
            </div>
            <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-3 d-flex flex-column justify-content-between'>
              <PurchaseSummaryChart
                className='card-xl-strech mb-2 mb-md-4 mb-xl-8 h-100 h-xl-auto'
                series={data.subcriptionsCouuntWithPackages.map((item) => item.subscribedUserCount)}
                categories={[]}
                title='Subscription Plans Purchased'
                subtitle=''
                labels={data.subcriptionsCouuntWithPackages.map((item) => item.subscriptionName)}
              />
              <MixedWidget
                className='card-xl-strech mb-xl-8 h-100'
                title='Total Revenue'
                description='Total revenue generated this week'
                series={data.revenueForMonths.map((item) => item.totalBalance)}
                amount={data.totalCashOnHand.toString()}
                months={data.revenueForMonths.map((item) => item.month)}
              />
            </div>
            <div className='col-xxl-12 mt-4'>
              <SalesbyCountry
                className='card-xl-strech mb-xl-6'
                title='World Sales'
                description='Top selling countries'
                amount='30,400'
                countryList={data.countryList}
              />
            </div>
            <div className='col-xxl-12 mt-4'>
              <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                <div className='col-xxl-12'>
                  <CustomTable data={data2} isLoading={isLoading} />
                </div>
              </div>
            </div>
            <div className='col-xxl-12 '>
              <div className='row g-5 gx-xl-10 my-5 my-xl-10'>
                <div className='col-md-4 col-lg-4 col-xl-4'>
                  <RefundStats
                    bg='primary'
                    title='Total Refunds'
                    amount={`${data4?.totalRefundsCounts}`}
                    icon='menu'
                  />
                </div>
                <div className='col-md-4 col-lg-4 col-xl-4'>
                  <RefundStats
                    bg='success'
                    title='Refunded'
                    amount={`${data4?.succeededRefundsCounts}`}
                    icon='verify'
                  />
                </div>
                <div className='col-md-4 col-lg-4 col-xl-4'>
                  <RefundStats
                    bg='danger'
                    title='Pending Refunds'
                    amount={`${data4?.pendingRefundsCounts}`}
                    icon='loading'
                  />
                </div>
              </div>
              <RefundsTable isHome data={refundsData} />
            </div>
          </div>
        </>
      )}
    </>
  )
}

const Home: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <HomePage />
    </>
  )
}

export default Home
