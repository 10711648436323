import {Column, HeaderProps} from 'react-table'
import {FC, PropsWithChildren, useState} from 'react'
import clsx from 'clsx'
import usePopup from '../../../../hooks/usePopup'
import {Menu} from '@headlessui/react'
import {KTIcon} from '../../../../_metronic/helpers/components/KTIcon'
import {Link} from 'react-router-dom'
import {RefundMode} from '../../../../models/refundModel'
import moment from 'moment'
import axios from 'axios'
import {toast} from 'react-toastify'
type TableHeaderProps = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<RefundMode>>
}

const TableHeader: FC<TableHeaderProps> = ({className, title, tableProps}) => {
  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(className)}
      style={{cursor: 'pointer'}}
    >
      {title}
    </th>
  )
}

const RefundsActionCell: FC<{item: any}> = ({item}) => {
  const BaseUrl = process.env.REACT_APP_BACKEND_URL

  const [trigger] = usePopup()

  const deleteItem = () => {
    trigger(
      'Delete',
      () => console.log('confirm'),
      () => console.log('reject')
    )
  }

  const [isApprove, setIsApprove] = useState(false)
  const giveRefund = () => async () => {
    try {
      setIsApprove(true)
      await axios
        .put(`${BaseUrl}/refund/approve/${item._id}`)
        .then((res) => {
          toast.success('Refund Approved')
        })
        .catch((err) => {
          toast.error('Refund Approval Failed')
        })
        .finally(() => {
          setIsApprove(false)
        })
    } catch (error) {
      throw error
    }
  }
  const [isReject, setIsReject] = useState(false)
  const rejectRefund = () => async () => {
    try {
      setIsReject(true)
      await axios
        .put(`${BaseUrl}/refund/reject/${item._id}`)
        .then((res) => {
          toast.success('Refund Rejected')
        })
        .catch((err) => {
          toast.error('Refund Rejection Failed')
        })
        .finally(() => {
          setIsReject(false)
        })
    } catch (error) {
      throw error
    }
  }

  return (
    <>
      <Menu>
        {({open}) => (
          <>
            <Menu.Button
              className='btn btn-light btn-active-light-primary btn-sm'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              Actions
              <KTIcon iconName='down' className='fs-5 m-0' />
            </Menu.Button>
            <Menu.Items
              className={clsx(
                'menu menu-sub d-flex menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4',
                open && 'show'
              )}
              style={{
                position: 'absolute',
                zIndex: 105,
                right: 0,
              }}
            >
              <Menu.Item as='div' className='menu-item px-3'>
                <Link to='#' className='menu-link px-3' onClick={giveRefund()}>
                  {isApprove ? 'Approving...' : 'Approve'}
                </Link>
              </Menu.Item>
              <Menu.Item as='div' className='menu-item px-3'>
                <Link to='#' className='menu-link px-3' onClick={rejectRefund()}>
                  {isReject ? 'Rejecting...' : 'Reject'}
                </Link>
              </Menu.Item>
            </Menu.Items>
          </>
        )}
      </Menu>
    </>
  )
}

const columns: ReadonlyArray<Column<RefundMode>> = [
  {
    id: 'user',
    Header: (props) => <TableHeader tableProps={props} title='name' className='min-w-125px' />,
    accessor: (row) => row.user.fullname,
  },
  {
    id: 'email',
    Header: (props) => <TableHeader tableProps={props} title='email' className='min-w-125px' />,
    accessor: (row) => row.user.email,
  },
  {
    id: 'reason',
    Header: (props) => <TableHeader tableProps={props} title='reason' className='min-w-125px' />,
    accessor: 'reason',
  },
  {
    id: 'amount',
    Header: (props) => <TableHeader tableProps={props} title='amount' className='min-w-125px' />,
    accessor: (row) => '$' + row.charge.amount / 100,
  },
  {
    id: 'status',
    Header: (props) => <TableHeader tableProps={props} title='status' className='min-w-125px' />,
    accessor: (row) => row.refundStatus,
  },
  {
    id: 'date',
    Header: (props) => <TableHeader tableProps={props} title='Date' className='min-w-125px' />,
    accessor: (row) => moment(row.createdAt).format('DD/MM/YYYY'),
  },
  {
    Header: (props) => (
      <TableHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <RefundsActionCell item={props.data[props.row.index]} />,
  },
]

export {columns}
