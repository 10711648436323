import {FC, useState} from 'react'
import {Dialog} from '@headlessui/react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'

import {KTIcon} from '../../../../_metronic/helpers/components/KTIcon'
import {User} from '../../../../app/modules/apps/user-management/users-list/core/_models'
import {toAbsoluteUrl} from '../../../../_metronic/helpers/AssetHelpers'

import axios from 'axios'
import {useAuth} from '../../../../app/modules/auth'
import {toast} from 'react-toastify'
import ImageInput from '../../../shared/ImageInput'
import {countries} from '../../../../Constants'
import {useDispatch} from 'react-redux'
import {updateCustomer} from '../../../../redux/slicers/customerSlice'

const schema = Yup.object().shape({
  fullname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  phone: Yup.number()
    .integer('Invalid number')
    .min(7, 'Minimum 7 symbols')
    .required('Phone is required'),
  username: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('username is required'),
  address: Yup.string().required('Address is required'),
  subcriptionplan: Yup.string().required('Subcription plan required'),
  status: Yup.string().required('Status plan required'),
})

const BaseUrl = process.env.REACT_APP_BACKEND_URL
const EditUserModal: FC<{user: User | undefined; close: () => void; isUserLoading: boolean}> = ({
  user,
  close,
  isUserLoading,
}) => {
  const {currentUser} = useAuth()
  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const dispatch = useDispatch()
  const [file, setFile] = useState<File | null>(null)

  const initialValues = {
    avatar: user?.profilePicture || blankImg,
    fullname: user?.name,
    email: user?.email,
    phone: user?.phone,
    username: user?.username,
    address: user?.address,
    subcriptionplan: user?.plan,
    status: user?.status,
    id: user?.id,
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const response = await axios.post(
          `${BaseUrl}/client/update-client-by-admin`,
          {
            email: values.email,
            username: values.username,
            fullname: values.fullname,
            phone: values.phone,
            address: values.address,
            subcrriptionPlan: values.subcriptionplan,
            status: values.status,
            clientId: values.id,
          },

          {
            headers: {
              Authorization: `Bearer ${currentUser?.api_token}`,
            },
          }
        )

        dispatch(updateCustomer(`${values.fullname} updated successfully`))
        toast.success('Client updated successfully')
      } catch (error: any) {
        if (error.response.status === 400 && error.response.data.message === 'nothing-to-update') {
          toast.warning('Nothing to update')
        } else if (
          error.response.status === 400 &&
          error.response.data.message === 'account-updated-email-not-sent'
        ) {
          toast.warning('Account updated but email not sent')
        }
      } finally {
        setSubmitting(true)
      }
    },
  })

  const handleSelectedFile = (e) => {
    // console.log(e)
    // const file = e.target.files[0]
    setFile(e)
  }

  return (
    <>
      <Dialog
        className='modal fade show d-block'
        open
        onClose={close}
        tabIndex={-1}
        aria-modal='true'
      >
        <Dialog.Panel className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <Dialog.Title as='h2' className='fw-bolder'>
                Edit Customer Details
              </Dialog.Title>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={close}
                style={{cursor: 'pointer'}}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            <div className='modal-body scroll-y my-7'>
              <form
                id='kt_modal_add_user_form'
                className='form mw-100'
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <div
                  className='d-flex flex-column scroll-y px-5 px-lg-10'
                  id='kt_modal_add_user_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_user_header'
                  data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                  data-kt-scroll-offset='300px'
                >
                  <div className='fv-row mb-7 pt-6'>
                    {/* <img alt='Logo' src={formik.values.avatar} /> */}
                    <ImageInput path={initialValues?.avatar} onChange={handleSelectedFile} />
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Full Name</label>

                    <input
                      placeholder='Full name'
                      {...formik.getFieldProps('fullname')}
                      type='text'
                      name='fullname'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.fullname && formik.errors.fullname},
                        {
                          'is-valid': formik.touched.fullname && !formik.errors.fullname,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    {formik.touched.fullname && formik.errors.fullname && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.fullname}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Email</label>

                    <input
                      placeholder='Email'
                      {...formik.getFieldProps('email')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.email && formik.errors.email},
                        {
                          'is-valid': formik.touched.email && !formik.errors.email,
                        }
                      )}
                      type='email'
                      name='email'
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.email}</span>
                      </div>
                    )}
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Username</label>

                    <input
                      placeholder='Username'
                      {...formik.getFieldProps('username')}
                      type='text'
                      name='name'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.username && formik.errors.username},
                        {
                          'is-valid': formik.touched.username && !formik.errors.username,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    {formik.touched.username && formik.errors.username && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.username}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Phone</label>

                    <input
                      placeholder='Phone'
                      {...formik.getFieldProps('phone')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.phone && formik.errors.phone},
                        {
                          'is-valid': formik.touched.phone && !formik.errors.phone,
                        }
                      )}
                      type='email'
                      name='phone'
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.phone}</span>
                      </div>
                    )}
                  </div>
                  <div className='row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Address</label>
                    <select
                      aria-label='Select an Address'
                      data-control='select2'
                      data-placeholder='Select an address...'
                      className={clsx(
                        'form-select form-select-solid form-select-lg fw-semibold select2-hidden-accessible',
                        {'is-invalid': formik.touched.address && formik.errors.address},
                        {'is-valid': formik.touched.address && !formik.errors.address}
                      )}
                      data-select2-id='select2-data-9-py72'
                      tabIndex={-1}
                      aria-hidden='true'
                      data-kt-initialized={1}
                      {...formik.getFieldProps('address')}
                    >
                      <option value='' data-select2-id='select2-data-11-0ljl'>
                        Select a Country...
                      </option>
                      {countries.map((country, i) => (
                        <option data-kt-flag={country.flag} value={country.value} key={i}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.address && formik.errors.address && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.address}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Status</label>
                    <select
                      aria-label='Select an Address'
                      data-control='select2'
                      data-placeholder='Select an address...'
                      className={clsx(
                        'form-select form-select-solid form-select-lg fw-semibold select2-hidden-accessible',
                        {
                          'is-invalid': formik.touched.status && formik.errors.status,
                        },
                        {
                          'is-valid': formik.touched.status && !formik.errors.status,
                        }
                      )}
                      data-select2-id='select2-data-9-py72'
                      tabIndex={-1}
                      aria-hidden='true'
                      data-kt-initialized={1}
                      {...formik.getFieldProps('status')}
                    >
                      <option value='' data-select2-id='select2-data-11-0ljl'>
                        Select a Status...
                      </option>
                      <option value='Active'>Active</option>
                      <option value='Deactive'>Deactive</option>
                      <option value='Pending'>Pending</option>
                      <option value='Suspended'>Suspended</option>
                    </select>
                    {formik.touched.status && formik.errors.status && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.status}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Subcription Plan</label>
                    <select
                      aria-label='Select an subscription plan'
                      data-control='select2'
                      data-placeholder='Select an subscription plan...'
                      className={clsx(
                        'form-select form-select-solid form-select-lg fw-semibold select2-hidden-accessible',
                        {
                          'is-invalid':
                            formik.touched.subcriptionplan && formik.errors.subcriptionplan,
                        },
                        {
                          'is-valid':
                            formik.touched.subcriptionplan && !formik.errors.subcriptionplan,
                        }
                      )}
                      data-select2-id='select2-data-9-py72'
                      tabIndex={-1}
                      aria-hidden='true'
                      data-kt-initialized={1}
                      {...formik.getFieldProps('subcriptionplan')}
                    >
                      <option value='' data-select2-id='select2-data-11-0ljl'>
                        Select an subscription plan...
                      </option>
                      <option value='Demo'>Demo</option>
                      <option value='Basic'>Basic</option>
                      <option value='Standard'>Standard</option>
                      <option value='Enterprise'>Enterprise</option>
                      <option value='3 Months Plan'>3 Months Plan</option>
                    </select>
                    {formik.touched.subcriptionplan && formik.errors.subcriptionplan && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.subcriptionplan}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='text-center pt-15'>
                  <button
                    onClick={close}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                    disabled={formik.isSubmitting || isUserLoading}
                  >
                    Discard
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={isUserLoading || formik.isSubmitting}
                  >
                    <span className='indicator-label'>Submit</span>
                    {(formik.isSubmitting || isUserLoading) && (
                      <span className='indicator-progress'>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default EditUserModal
