import React, {FC, useEffect, useLayoutEffect, useMemo, useRef} from 'react'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow'
import am5geodata_usaLow from '@amcharts/amcharts5-geodata/usaLow'
import * as am5map from '@amcharts/amcharts5/map'

type Props = {
  className: string
  title: string
  description: string
  amount: string
  countryList: countryCounts[]
}

type countryCounts = {
  country: string
  count: number
}

const SalesbyCountry: FC<Props> = ({className, title, description, amount, countryList}) => {
  const chartColor = 'danger'
  const chartHeight = '180px'

  console.log('countryList: ', countryList)

  const mapData = useMemo(() => {
    if (Array.isArray(countryList[0])) {
      return countryList.map((country) => {
        return {
          id: country.country,
          name: country.country,
          count: country.count,
        }
      })
    }
    return []
  }, [countryList])

  const chartRef = useRef<any>()

  useLayoutEffect(() => {
    let root
    root = am5.Root.new('mapChart')

    am5.ready(function () {
      root.setThemes([am5themes_Animated.new(root)])
      var chart = root.container.children.push(
        am5map.MapChart.new(root, {
          panX: 'translateX',
          panY: 'translateY',
          projection: am5map.geoMercator(),
          wheelY: 'none',
        })
      )

      let polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodata_worldLow,
          valueField: 'value',
          calculateAggregates: true,
          exclude: ['AQ'],
        })
      )

      polygonSeries.mapPolygons.template.setAll({
        // modify these two values to change the tooltip.
        tooltipText: '{name}: {count}',
        toggleKey: 'active',
        interactive: true,
        // modify opacity of the rest of the world
        fillOpacity: 0.6,
        // add template field to access data
        templateField: 'polygonSettings',
      })

      // set chart data from here
      polygonSeries.data.setAll(
        // to add properties that changes the color
        mapData.map((item) => ({
          ...item,
          polygonSettings: {
            fill: am5.color(0x68dc76),
            // modify opacity of countries with sales
            fillOpacity: 1,
          },
        }))
      )

      polygonSeries.mapPolygons.template.on('active', function (active, target) {
        console.log('target: ', target.dataItem.dataContext, active)
      })

      polygonSeries.mapPolygons.template.states.create('hover', {
        fill: root.interfaceColors.get('primaryButtonHover'),
      })
      chartRef.current = chart
    })

    return () => root && root.dispose()
  }, [mapData])

  // useEffect(() => {
  //   if (chartRef.current) {
  //     // chartRef.current.data?.setAll(mapData)
  //     console.info('chartRef data', chartRef.current.data)
  //   }
  // }, [chartRef.current, mapData])

  return (
    <div className={`card ${className}`}>
      <div className='card-body d-flex flex-column p-0'>
        <div className='flex-grow-1 card-p pb-0 mb-4'>
          <div className='d-flex flex-stack flex-wrap'>
            <div className='me-2'>
              <span className='text-dark text-hover-primary fw-bold fs-3'>{title}</span>
              <div className='text-muted fs-7 fw-semibold'>{description}</div>
            </div>
            {/* <div className={`fw-bold fs-3 text-${chartColor}`}>${amount}</div> */}
          </div>
          <div
            id='mapChart'
            className='card-rounded-bottom'
            style={{width: '100%', height: '500px'}}
          />
        </div>
      </div>
    </div>
  )
}

export default SalesbyCountry
