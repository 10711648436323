import clsx from 'clsx'
import {KTIcon, defaultAlerts, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Search, ThemeModeSwitcher} from '../../../_metronic/partials'
import {useLayout} from '../../../_metronic/layout/core'
import UserMenu from './UserMenu'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../app/modules/auth'
import axios from 'axios'
import {useEffect, useState} from 'react'
import {NotificationsProps} from '../../../app/pages/profile/Notifications'

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const {currentUser, logout} = useAuth()
  const {config} = useLayout()
  const BaseUrl = process.env.REACT_APP_BACKEND_URL
  const [notifications, setNotifications] = useState<NotificationsProps[]>([])
  const getNotifications = async () => {
    try {
      await axios
        .get(`${BaseUrl}/notification/`)
        .then((response) => {
          console.log(response.data.notifications)
          setNotifications(response.data.notifications)
        })
        .catch((error) => {})
    } catch (error) {
      throw error
    }
  }

  useEffect(() => {
    getNotifications()
  }, [])
  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('position-relative', btnClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <KTIcon iconName='notification' className={btnIconClass} />
          {/* <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' /> */}
        </div>
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold menu-column w-350px w-lg-375px'
          data-kt-menu='true'
        >
          <div
            className='d-flex flex-column bgi-no-repeat rounded-top'
            style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`}}
          >
            <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
              Notifications{' '}
              <span className='fs-8 opacity-75 ps-3'>
                {`${notifications.length}`} Notifications
              </span>
            </h3>
          </div>
          <div className='card-body'>
            <div className='my-5 px-8'>
              {notifications.map((alert, index) => (
                <div key={`alert${index}`} className='d-flex flex-stack py-4'>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-35px me-4'>
                      <span className={clsx('symbol-label', `bg-light-${alert.clientid}`)}>
                        {' '}
                        <KTIcon
                          iconName={'cloud-change'}
                          className={`fs-2 text-${alert.clientid}`}
                        />
                      </span>
                    </div>

                    <div className='mb-0 me-2'>
                      <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                        {alert.title}
                      </a>
                      <div className='text-gray-400 fs-7'>{alert.description}</div>
                    </div>
                  </div>

                  <span className='badge badge-light fs-8'>{alert.createdAt}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol symbol-circle', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img alt='Logo' src={toAbsoluteUrl(currentUser?.pic || '')} />
        </div>
        <UserMenu />
      </div>

      {/* {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )} */}
    </div>
  )
}

export default Navbar
