import axios from 'axios'
const BaseUrl = process.env.REACT_APP_BACKEND_URL

export async function getRefunds(token: string) {
  try {
    const response = await axios.get(`${BaseUrl}/refunds/getrefunds`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log(response.data)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function refundCount(token: string) {
  try {
    const response = await axios.get(`${BaseUrl}/refunds/getRefundsCounts`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log(response.data)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function giveRefund(token: string, id: string) {
  try {
    const response = await axios.get(`${BaseUrl}/client/getClientDetails/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log(response.data)
    return response.data
  } catch (error) {
    throw error
  }
}
