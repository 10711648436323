import {FC, useEffect, useMemo, useState} from 'react'
import {KTCard, KTIcon} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {Row, useTable} from 'react-table'
import {columns as _columns} from './TableColumns'
import {CustomHeaderColumn} from '../../../../app/modules/apps/user-management/users-list/table/columns/CustomHeaderColumn'
import {TableRow} from './TableRow'

import clsx from 'clsx'

const RefundsTable: FC<{isHome?: boolean; data: any}> = ({isHome = false, data}) => {
  const PAGINATION = useMemo(
    () => ({
      totalItems: data.length,
      itemsPerPage: 10,
      totalPages: Math.ceil(data.length / 10),
      currentPage: 1,
    }),
    [data]
  )

  const [pagination, setPagination] = useState(PAGINATION)
  const [tableData, setTableData] = useState<any[]>([])

  // update pagination and items per page
  const updatePage = (pageNumber: number, itemsPerPage: number) => {
    const totalPages = Math.ceil(data.length / itemsPerPage)

    if (pageNumber <= 0 || pageNumber >= totalPages + 1) {
      return
    }
    const refinedIndex = Math.abs(pageNumber - 1)

    // can use this part to call paginated API.
    const offset = refinedIndex * itemsPerPage
    const limit = itemsPerPage * pageNumber
    const dataForPage = [...data].slice(offset, limit)
    setTableData(dataForPage)

    setPagination({
      ...pagination,
      currentPage: pageNumber,
      itemsPerPage: itemsPerPage,
      totalPages: Math.ceil(data.length / itemsPerPage),
    })
  }
  useEffect(() => {
    setPagination(PAGINATION)
    updatePage(1, 10)
  }, [data])

  const [ipp, setIpp] = useState(pagination.itemsPerPage)

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns: _columns as any,
    data: tableData,
  })

  const [searchTerm, setSearchTerm] = useState('')

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6 d-flex flex-column mb-8'>
          <h3 className='card-title align-items-start flex-column pt-6 '>
            <span className='card-label fw-bold fs-3 mb-1'>Refunds</span>
          </h3>

          <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
            <div className='min-w-150px'>
              <select
                className='form-select form-select-solid select2-hidden-accessible'
                placeholder='Select Status'
                tabIndex={-1}
                aria-hidden='true'
                onChange={(e) => {
                  setSearchTerm(e.target.value)
                }}
              >
                <option data-select2-id='select2-data-11-7e1a'>Select Status</option>
                <option value='' data-select2-id='select2-data-127-k7ag'>
                  All
                </option>
                <option value='succeeded' data-select2-id='select2-data-128-1i2i'>
                  Succeeded
                </option>
                <option value='pending' data-select2-id='select2-data-129-7w8r'>
                  Pending
                </option>
                <option value='failed' data-select2-id='select2-data-130-kpp7'>
                  Failed
                </option>
                <option value='canceled' data-select2-id='select2-data-131-buvn'>
                  Canceled
                </option>
                <option value='disputed' data-select2-id='select2-data-131-buvn'>
                  Disputed
                </option>
              </select>
            </div>
          </div>
          {/* <TableToolbar /> */}
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table
              id='kt_table_users'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
              {...getTableProps()}
            >
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  {headers.map((column: any) => (
                    <CustomHeaderColumn key={column.id} column={column} />
                  ))}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold' {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  rows
                    .filter((row: Row<any>) => {
                      return row.cells.some((cell) => {
                        return String(cell.value).toLowerCase().includes(searchTerm.toLowerCase())
                      })
                    })
                    .map((row: Row<any>, i) => {
                      prepareRow(row)
                      return <TableRow row={row} key={`row-${i}-${row.id}`} />
                    })
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className='row'>
            <div className='col-sm-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start'>
              <label>
                <select
                  value={ipp}
                  onChange={(e) => {
                    setIpp(Number(e.target.value))
                    updatePage(pagination.currentPage, Number(e.target.value))
                  }}
                  className='form-select form-select-sm form-select-solid'
                >
                  <option value='10'>10</option>
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                </select>
              </label>
            </div>
            <div className='col-sm-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end'>
              <div className=''>
                <ul className='pagination'>
                  <li
                    className='page-item'
                    onClick={() => updatePage(pagination.currentPage - 1, ipp)}
                  >
                    <a className='page-link'>
                      <KTIcon iconName='left' className='fs-1 text-dark' />
                    </a>
                  </li>
                  {Array(pagination.totalPages)
                    .fill(null)
                    .map((_, i) => {
                      const _index = i + 1
                      return (
                        <li
                          className={clsx(
                            'page-item',
                            pagination.currentPage === _index ? 'active' : ''
                          )}
                          onClick={() => updatePage(_index, ipp)}
                          key={i}
                        >
                          <a className='page-link'>{_index}</a>
                        </li>
                      )
                    })}

                  <li
                    className='page-item'
                    onClick={() => updatePage(pagination.currentPage + 1, ipp)}
                  >
                    <a className='page-link'>
                      <KTIcon iconName='right' className='fs-1 text-dark' />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {isHome && (
            <div className='d-flex w-100 justify-content-center my-2'>
              <Link to='/refunds' className='btn fw-bold btn-primary'>
                <span className=''>See All Refunds</span>
              </Link>
            </div>
          )}
        </div>
      </KTCard>
    </>
  )
}

export default RefundsTable
