import clsx from 'clsx'
import React, {FC} from 'react'
import {KTIcon} from '../../../_metronic/helpers'

const RefundStats: FC<{bg: string; title: string; amount: string; icon: string}> = ({
  bg,
  title,
  amount,
  icon,
}) => {
  return (
    <div className={clsx('card hoverable card-xl-stretch mb-xl-8', `bg-${bg}`)}>
      <div className='card-body'>
        <KTIcon iconName={icon} className='fs-2x text-white ms-n1' />
        <div className='text-white fw-bold fs-2 mb-2 mt-5'>{title}</div>
        <div className='fw-semibold text-white'>{amount} Orders </div>
      </div>
    </div>
  )
}

export default RefundStats
